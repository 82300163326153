<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Tagging comment group members</h3>

	<p><img alt="Tagging location image" srcset="/docimages/comment_attn-1.png 3x" class="k-help-img float-right">When creating a new comment directed at a <span v-html="link('comment_groups', 'comment group')"></span>, you can tag the comment to another group member’s attention by selecting the member’s name from the “Attn” menu in the upper-right corner of the comment editor interface.</p>
	<ul>
		<li>If you tag a comment to someone’s attention, or if someone else tags <i>you</i> on a comment, the comment will appear to the tagged user with an orange background (instead of the normal green background), and the tagged user’s initial will appear in the upper-right corner of the comment:</li>
	</ul>
	<img alt="Tagged comment example image" srcset="/docimages/comment_attn-2.png 2x" class="k-help-img block">
	<ul>
		<li>In addition, comments tagged to your attention will always appear at the top of the <span v-html="link('show_all_comments', 'All Framework Comments')"></span> table, until the comment is <span v-html="link('comment_mark_as_read', 'marked as read')"></span>.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	